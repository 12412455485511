var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('wolk-private-data-alert')],1)],1),(!_vm.loading)?[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Locatie "+_vm._s(_vm.location ? _vm.location.title : 'laden'))])])],1),((_vm.$store.state.auth.type === 'admin' || _vm.$store.state.auth.isAdmin) && _vm.organization)?_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('p',{staticClass:"mb-0"},[_vm._v(" Organisatie "),_c('router-link',{attrs:{"to":{
                  name: 'organizationsShow',
                  params: { id: _vm.organization._id },
                }}},[_vm._v(" "+_vm._s(_vm.organization.title)+" ")])],1)])],1)],1)],1):_vm._e(),_c('analytics-v3-block',{attrs:{"scope":"location","scope-id":_vm.$route.params.id}}),(_vm.analyticsData)?_c('wolk-statuses-block',{attrs:{"analytics-data":_vm.analyticsData}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.wolkHeaders,"items":_vm.wolks,"options":_vm.wolkTableSort,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"loading":_vm.loading},on:{"update:options":function($event){_vm.wolkTableSort=$event}},scopedSlots:_vm._u([{key:"item.metaData.sleevePublicId",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'wolksShow', params: { id: item._id } }}},[_vm._v(" "+_vm._s(item.metaData.sleevePublicId)+" ")])]}},{key:"item.metaData.organizationTitle",fn:function(ref){
                var item = ref.item;
return [(item.metaData && item.metaData.organizationTitle)?_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item.organizationId } }}},[_vm._v(" "+_vm._s(item.metaData.organizationTitle)+" ")]):_vm._e()]}},{key:"item.metaData.locationTitle",fn:function(ref){
                var item = ref.item;
return [(item.metaData && item.metaData.locationTitle)?_c('router-link',{attrs:{"to":{ name: 'locationsShow', params: { id: item.locationId } }}},[_vm._v(" "+_vm._s(item.metaData.locationTitle)+" ")]):_vm._e()]}},{key:"item.metaData.size",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.metaData.size)+" ")]}},{key:"item.currentStatusInfo.batteryPercentage",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getHumanizedBatteryPercentage(item).color}},[_vm._v(" "+_vm._s(_vm.getHumanizedBatteryPercentage(item).name)+" ")])]}},{key:"item.currentStatusInfo.firmwareVersion",fn:function(ref){
                var item = ref.item;
return [_c('firmware-version-chip',{attrs:{"status-info":item.currentStatusInfo || {}}})]}},{key:"item.currentStatusInfo.parsedStatusKey",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusInfo(item.currentStatus).color,"text-color":_vm.getStatusInfo(item.currentStatus).inversTextColor ? 'white' : 'black'}},[_vm._v(" "+_vm._s(_vm.getStatusInfo(item.currentStatus).title)+" ")])]}},{key:"item.currentStatusInfo.createdAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentStatusInfo ? _vm.getHumanizedDate(item.currentStatusInfo.createdAt) : '--')+" ")]}},{key:"item.retour.status",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getIconForRetourStatus(item.retour))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getHumanizedRetourStatus(item.retour)))])])]}}],null,true)})],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }