<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <template v-if="!loading">
      <v-row>
        <v-col>
          <h2>Locatie {{ location ? location.title : 'laden' }}</h2>
        </v-col>
      </v-row>

      <v-row
        v-if="($store.state.auth.type === 'admin' || $store.state.auth.isAdmin) && organization"
      >
        <v-col>
          <v-card>
            <v-card-text>
              <p class="mb-0">
                Organisatie

                <router-link
                  :to="{
                    name: 'organizationsShow',
                    params: { id: organization._id },
                  }"
                >
                  {{ organization.title }}
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <analytics-v3-block
        scope="location"
        :scope-id="$route.params.id"
      />

      <wolk-statuses-block
        v-if="analyticsData"
        :analytics-data="analyticsData"
      />

      <v-row>
        <v-col>
          <v-card>
            <v-data-table
              :headers="wolkHeaders"
              :items="wolks"
              :options.sync="wolkTableSort"
              :items-per-page="20"
              :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
              :loading="loading"
            >
              <template #[`item.metaData.sleevePublicId`]="{ item }">
                <router-link :to="{ name: 'wolksShow', params: { id: item._id } }">
                  {{ item.metaData.sleevePublicId }}
                </router-link>
              </template>

              <template #[`item.metaData.organizationTitle`]="{ item }">
                <router-link
                  v-if="item.metaData && item.metaData.organizationTitle"
                  :to="{ name: 'organizationsShow', params: { id: item.organizationId } }"
                >
                  {{ item.metaData.organizationTitle }}
                </router-link>
              </template>

              <template #[`item.metaData.locationTitle`]="{ item }">
                <router-link
                  v-if="item.metaData && item.metaData.locationTitle"
                  :to="{ name: 'locationsShow', params: { id: item.locationId } }"
                >
                  {{ item.metaData.locationTitle }}
                </router-link>
              </template>

              <template #[`item.metaData.size`]="{ item }">
                {{ item.metaData.size }}
              </template>

              <template #[`item.currentStatusInfo.batteryPercentage`]="{ item }">
                <v-chip
                  :color="getHumanizedBatteryPercentage(item).color"
                >
                  {{ getHumanizedBatteryPercentage(item).name }}
                </v-chip>
              </template>

              <template #[`item.currentStatusInfo.firmwareVersion`]="{ item }">
                <firmware-version-chip :status-info="item.currentStatusInfo || {}" />
              </template>

              <template #[`item.currentStatusInfo.parsedStatusKey`]="{ item }">
                <v-chip
                  :color="getStatusInfo(item.currentStatus).color"
                  :text-color="
                    getStatusInfo(item.currentStatus).inversTextColor ? 'white' : 'black'
                  "
                >
                  {{ getStatusInfo(item.currentStatus).title }}
                </v-chip>
              </template>

              <template #[`item.currentStatusInfo.createdAt`]="{ item }">
                {{
                  item.currentStatusInfo ?
                    getHumanizedDate(item.currentStatusInfo.createdAt) :
                    '--'
                }}
              </template>
              <template #[`item.retour.status`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getIconForRetourStatus(item.retour) }}
                    </v-icon>
                  </template>
                  <span>{{ getHumanizedRetourStatus(item.retour) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';

import {
  createBreadcrumbs,
  getHumanizedBatteryPercentage,
  getHumanizedDate,
  getStatusInfo,
} from '../../helpers/humanizer';

import AnalyticsV3Block from '../../components/AnalyticsV3Block.vue';
import FirmwareVersionChip from '../../components/FirmwareVersionChip.vue';
import WolkStatusesBlock from '../../components/WolkStatusesBlock.vue';
import WolkPrivateDataAlert from '../../components/WolkPrivateDataAlert.vue';

export default {
  name: 'LocationShow',
  components: {
    AnalyticsV3Block,
    FirmwareVersionChip,
    WolkStatusesBlock,
    WolkPrivateDataAlert,
  },
  data() {
    return {
      loading: false,
      analyticsData: null,
      location: null,
      organization: null,
      wolks: [],
      users: [],
      wolkTableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['currentStatusInfo.createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Locaties',
            disabled: false,
            exact: true,
            to: { name: 'locations' },
          },
          {
            text: `Locatie ${this.location ? this.location.title : 'loading'}`,
            disabled: true,
            exact: true,
            to: { name: 'locationsShow', params: { id: this.$route.params.id } },
          },
        ],
        false,
      );
    },
    wolkHeaders() {
      if (this.$store.state.auth.type === 'admin') {
        return [
          { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Type', value: 'type' },
          { text: 'Maat', value: 'metaData.size' },
          { text: 'Retour', value: 'retour.status', sortable: true },
          { text: 'Status', value: 'currentStatusInfo.parsedStatusKey' },
          { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage' },
          { text: 'Firmware', value: 'currentStatusInfo.firmwareVersion' },
          { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
        ];
      }

      if (this.organization?.retourEnabled) {
        return [
          { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'Maat', value: 'metaData.size' },
          { text: 'Retour', value: 'retour.status', sortable: true },
          { text: 'Status', value: 'currentStatusInfo.parsedStatusKey' },
          { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage' },
          { text: 'Firmware', value: 'currentStatusInfo.firmwareVersion' },
          { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
        ];
      }

      return [
        { text: 'Wolknr.', value: 'metaData.sleevePublicId', sortable: false },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'Maat', value: 'metaData.size' },
        { text: 'Status', value: 'currentStatusInfo.parsedStatusKey' },
        { text: 'Batterij', value: 'currentStatusInfo.batteryPercentage' },
        { text: 'Firmware', value: 'currentStatusInfo.firmwareVersion' },
        { text: 'Laatst online', value: 'currentStatusInfo.createdAt' },
      ];
    },
  },
  beforeMount() {
    this.loadLocation();
    this.loadAnalyticsData();
  },
  methods: {
    getHumanizedDate(value) {
      return getHumanizedDate(value);
    },
    getHumanizedBatteryPercentage(value) {
      return getHumanizedBatteryPercentage(value, true);
    },
    getStatusInfo(key) {
      return getStatusInfo(key);
    },
    loadLocation() {
      const url = `${config.VUE_APP_API_BASE_URL}/locations/${this.$route.params.id}`;
      this.loading = true;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.location = response.data.location;
          this.organization = response.data.organization;
          this.wolks = response.data.wolks;
          this.users = response.data.users;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadAnalyticsData() {
      const url = `${config.VUE_APP_API_BASE_URL}/analytics`;
      const params = {
        locationId: this.$route.params.id,
      };

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
      })
        .then((response) => {
          this.analyticsData = response.data;
        });
    },
    getHumanizedRetourStatus(retour) {
      const status = retour?.status;
      const retourReason = retour?.reason;

      if (status === 'exchange') {
        if (retourReason === 'maintenance') {
          return 'onderhoud - omruilen';
        }
        return 'omruilen';
      }
      if (status === 'sent') {
        return 'verzonden';
      }
      if (status === 'maintenance') {
        return 'onderhoud';
      }
      if (status === 'retour') {
        return 'retour';
      }
      return '';
    },
    getIconForRetourStatus(retour) {
      const retourStatus = retour?.status;
      const retourReason = retour?.reason;

      if (retourStatus === 'sent') {
        return 'mdi-email-fast-outline';
      }
      if (retourStatus === 'maintenance') {
        return 'mdi-wrench';
      }
      if (retourStatus === 'exchange') {
        if (retourReason === 'maintenance') {
          return 'mdi-wrench';
        }
        return 'mdi-swap-horizontal';
      }
      if (retourStatus === 'retour') {
        return 'mdi-arrow-right';
      }
      return '';
    },
  },
};
</script>
